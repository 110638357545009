export default function getDateString(date: string): string {
  const t = new Date(date)

  const addSpace = (num: number): string => {
    return num > 9 ? `${num}` : `0${num}`
  }

  const day = addSpace(t.getDate())
  const month = addSpace(t.getMonth() + 1)
  const year = t.getFullYear()
  const hour = addSpace(t.getHours())
  const minute = addSpace(t.getMinutes())

  const result = `${day}.${month}.${year} ${hour}:${minute}`
  return result
}
