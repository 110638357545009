import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'

import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import CompanySettingsDashboard from './CompanySettingsDashboard'
import SettingsNavigation from './SettingsNavigation'

//TODO: implement shops

const SettingsPage: React.FC = () => {
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const settingsBreadCrumbs: Array<PageLink> = [
    {
      title: msg('MENU.SETTINGS'),
      path: '/settings/company',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        path='company'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>{msg('USER.SETTINGS.COMPANY')}</PageTitle>
            <CompanySettingsDashboard />
          </>
        }
      />
      <Route
        path='shops'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>{msg('USER.SETTINGS.SHOPS')}</PageTitle>
            <SettingsNavigation />
          </>
        }
      />
      <Route index element={<Navigate to='/settings/company' />} />
    </Routes>
  )
}

export default SettingsPage
