import {useEffect} from 'react'

import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ThemeModeSwitcher} from '../../../../../_metronic/partials'
import {AuthLanguages} from './AuthLanguages'

export function AuthHeader() {
  useEffect(() => {
    MenuComponent.bootstrap()
  }, [])

  return (
    <div className='d-flex justify-content-end'>
      <div className='menu me-2' data-kt-menu='true'>
        <ThemeModeSwitcher menuPlacement='left-start' />
      </div>
      <AuthLanguages />
    </div>
  )
}
