import {Buffer} from 'buffer'
import {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'

import {UserMerchantModel} from '../../../../auth'
import {useErrorManagerForComponent} from '../../../../errors/ErrorManager'
import {openFile, revokeUrl} from '../_functions'
import {MerchantFile, MerchantFileName} from '../_models'
import {getFiles} from '../_requests'
import {allowedTypes} from '../UseMerchantValidation'

type Props = {
  show: boolean
  closeModal: () => void
  merchants: UserMerchantModel[] | undefined
  intl: (id: string) => string
}

type MerchantData = {
  name: 'name' | 'tin' | 'email' | 'phone' | 'address' | 'company' | 'activity'
  label: string
  value: string
}

export default function MerchantDataModal(props: Props) {
  const {show, closeModal, merchants, intl: msg} = props

  const getErrorMessage = useRef(useErrorManagerForComponent('KYCModal'))

  const [foundingDocument, setFoundingDocument] = useState<MerchantFile | undefined>()
  const [capitalSource, setCapitalSource] = useState<MerchantFile | undefined>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const [data] = merchants ?? []

  const {
    id,
    companyName,
    vatNumber,
    companyEmail,
    companyAddress,
    companyPhone,
    companyType,
    activityType,
  } = data ?? {}

  const merchantData: MerchantData[] = [
    {name: 'name', label: msg('ADMIN.KYC.COMPANY_NAME'), value: companyName},
    {name: 'tin', label: msg('ADMIN.KYC.TIN'), value: vatNumber},
    {name: 'email', label: msg('ADMIN.KYC.COMPANY_EMAIL'), value: companyEmail},
    {name: 'phone', label: msg('ADMIN.KYC.COMPANY_PHONE'), value: companyPhone},
    {name: 'address', label: msg('ADMIN.KYC.COMPANY_ADDRESS'), value: companyAddress},
    {name: 'company', label: msg('ADMIN.KYC.COMPANY_TYPE'), value: companyType},
    {name: 'activity', label: msg('ADMIN.KYC.ACTIVITY_TYPE'), value: activityType},
  ]

  const handleClose = () => {
    closeModal()
  }

  const inputField = (data: {label: string; value: string}) => {
    return (
      <input
        type='text'
        readOnly
        className='form-control bg-transparent w-50'
        id={data.label}
        value={data.value}
      />
    )
  }

  const textArea = (data: {label: string; value: string}) => {
    return (
      <textarea
        readOnly
        className='form-control bg-transparent w-50'
        id={data.label}
        value={data.value}
      />
    )
  }

  //used to revoke url in cleanup
  const fUrlRef = useRef<string>()
  const cUrlRef = useRef<string>()

  //used for opening file
  const [fUrl, setFUrl] = useState<string>()
  const [cUrl, setCUrl] = useState<string>()

  //generate urls to open files
  useEffect(() => {
    async function createFileUrl(file: MerchantFile, target: MerchantFileName) {
      const buffer = Buffer.from(file.data.data)
      const blob = new Blob([buffer], {type: allowedTypes[0]})

      const fileURL = window.URL.createObjectURL(blob)

      const founding = target === 'foundingDocument'
      const capital = target === 'capitalSource'

      if (founding) {
        setFUrl(fileURL)
        fUrlRef.current = fileURL
      }

      if (capital) {
        setCUrl(fileURL)
        cUrlRef.current = fileURL
      }
    }

    if (foundingDocument) createFileUrl(foundingDocument, 'foundingDocument')
    if (capitalSource) createFileUrl(capitalSource, 'capitalSource')

    //closes URL when file changes
    return () => {
      if (fUrlRef.current) revokeUrl(fUrlRef.current)
      if (cUrlRef.current) revokeUrl(cUrlRef.current)
    }
  }, [capitalSource, foundingDocument])

  //opens file in new window
  const handleOpen = (target: MerchantFileName) => {
    const founding = target === 'foundingDocument'
    const capital = target === 'capitalSource'

    if (founding) {
      if (!foundingDocument || !fUrl) return
      openFile(fUrl, foundingDocument.name)
    }

    if (capital) {
      if (!capitalSource || !cUrl) return
      openFile(cUrl, capitalSource.name)
    }
  }

  //hook that fetches when id changes, as long as id is defined
  //generally admin shouldnt have a merchant(no merchant id)
  //if he does, this will cause a fetch that could be unnecessary on component start
  useEffect(() => {
    const fetchFiles = async (signal: AbortSignal) => {
      setLoading(true)

      await getFiles(id, signal)
        .then((res) => res.data)
        .then((data) => {
          const {capitalSource, foundingDocument} = data

          setCapitalSource({...capitalSource, size: capitalSource.data.data.length})
          setFoundingDocument({...foundingDocument, size: foundingDocument.data.data.length})
          setErrorMessage('')
        })
        .catch((err) => {
          const errMsg = getErrorMessage.current(err?.response?.status)
          setErrorMessage(errMsg)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const controller = new AbortController()

    if (id) fetchFiles(controller.signal)

    return () => {
      controller.abort()
    }
  }, [id])

  return (
    <Modal
      id='merchant-kyc-modal'
      show={show}
      onHide={handleClose}
      centered
      dialogClassName='justify-content-center'
      contentClassName='w-400px'
    >
      <Modal.Header className='border-0 justify-content-center p-10 pb-0' closeButton>
        <Modal.Title className=''>{msg('ADMIN.KYC.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-10 mt-5'>
        <div className='d-flex'>
          <div className='col'>
            {errorMessage && (
              <div className='alert alert-danger text-center'>
                <div className='alert-text font-weight-bold'>{errorMessage}</div>
              </div>
            )}
            {merchantData.map((data, index) => {
              const {name, label} = data

              const fieldType = name === 'address' ? textArea(data) : inputField(data)
              return (
                <div className='row mb-3' key={`kyc-label-${index}`}>
                  <label className='col-form-label w-50'>{label}</label>
                  {fieldType}
                </div>
              )
            })}
            <div className='row mt-5'>
              <div className='col pe-2'>
                <button
                  className='btn-bg-light btn btn-sm text-hover-primary w-100 h-100'
                  type='button'
                  disabled={!foundingDocument || loading}
                  data-kt-indicator={loading ? 'on' : 'off'}
                  onClick={() => handleOpen('foundingDocument')}
                >
                  <span className='indicator-label'>{msg('ADMIN.KYC.FOUNDING_DOCUMENT')}</span>
                  <span className='indicator-progress'>
                    {msg('APP.LOADING')}
                    <span className='spinner-border spinner-border-sm align-middle ms-5'></span>
                  </span>
                </button>
              </div>
              <div className='col ps-2 '>
                <button
                  className='btn-bg-light btn btn-sm text-hover-primary w-100 h-100'
                  type='button'
                  disabled={!capitalSource || loading}
                  data-kt-indicator={loading ? 'on' : 'off'}
                  onClick={() => handleOpen('capitalSource')}
                >
                  <span className='indicator-label'>{msg('ADMIN.KYC.CAPITAL_SOURCE')}</span>
                  <span className='indicator-progress'>
                    {msg('APP.LOADING')}
                    <span className='spinner-border spinner-border-sm align-middle ms-5'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0 justify-content-center p-10 pt-0'>
        <div className='d-flex justify-content-between w-100 mx-0'>
          <button
            id='kyc-modal-close'
            className='btn-light btn btn-sm bg-hover-light text-success w-100'
            onClick={handleClose}
          >
            {msg('ADMIN.KYC.BUTTON.CLOSE')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
