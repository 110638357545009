/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

import {useAuth, UserMerchantModel} from '../../../../auth'
import {useErrorManagerForComponent} from '../../../../errors/ErrorManager'
import {defaultPaginationAmount, paginationOptions} from '../../general/pagination'
import {Direction} from '../../orders/_models'
import {MerchantColumn, MerchantResultOrder, MerchantStatus} from '../_models'
import {getPaginatedMerchants, updateMerchantStatus} from '../_requests'
import MerchantActionModal from './MerchantActionModal'
import MerchantDataModal from './MerchantDataModal'

type Arrow = 'left' | 'right'
type SelectionStatus = [confirmed, disabled]

type confirmed = boolean | undefined
type disabled = boolean | undefined

export default function MerchantTable() {
  const {setCurrentUser, currentUser} = useAuth()
  const intl = useIntl()
  const navigateTo = useNavigate()

  //stow away current user merchants, restore on unmount
  const backupUserMerchants = useRef(currentUser?.merchants)

  //i18n functionality
  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const yes = msg('ADMIN.MERCHANTS.BOOL.YES')
  const no = msg('ADMIN.MERCHANTS.BOOL.NO')

  //default ordering column
  const defaultColumn: MerchantColumn = 'isConfirmed'
  const [currentColumn, setCurrentColumn] = useState<MerchantColumn>(defaultColumn)

  //default merchant sorting category
  const defaultSelection: string = msg('ADMIN.MERCHANTS.SHOW.CONFIRMED')
  const [currentSelection, setCurrentSelection] = useState<string>(defaultSelection)

  //table data and pagination settings
  const [merchants, setMerchants] = useState<UserMerchantModel[]>([])
  const [take, setTake] = useState<number>(defaultPaginationAmount)
  const [page, setPage] = useState<number>(1)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [direction, setDirection] = useState<Direction>('desc')

  const [loading, setLoading] = useState<boolean>(true)

  //error handling | ref used because function doesn't change, to not cause rerenders
  const [errorMessage, setErrorMessage] = useState<string>('')
  const errorManager = useRef(useErrorManagerForComponent('MerchantTable'))
  const getErrorMessage = errorManager.current
  const removeErrorMessage = () => {
    setErrorMessage('')
  }

  //used in action modal
  const [target, setTarget] = useState<string>('error')
  const [action, setAction] = useState<string>('error')
  const [showActionModal, setShowActionModal] = useState<boolean>(false)
  const [callback, setCallback] = useState<() => void>(() => {})

  const closeActionModal = () => {
    setShowActionModal(false)
  }

  const openActionModal = () => {
    setShowActionModal(true)
  }

  //used in data/KYC modal
  const [showDataModal, setShowDataModal] = useState<boolean>(false)

  const closeDataModal = () => {
    setShowDataModal(false)
  }

  const openDataModal = () => {
    setShowDataModal(true)
  }

  //used to generate columns for table and carry property names for *order* in pagination
  const cols = new Map<string, MerchantColumn>()
  cols.set(msg('ADMIN.MERCHANTS.ID'), 'id')
  cols.set(msg('ADMIN.MERCHANTS.NAME'), 'name')
  cols.set(msg('ADMIN.MERCHANTS.TAX_NUMBER'), 'vatNumber')
  cols.set(msg('ADMIN.MERCHANTS.CONFIRMED'), 'isConfirmed')
  cols.set(msg('ADMIN.MERCHANTS.DISABLED'), 'isDisabled')
  const colsArr = Array.from(cols)

  //used to generate select options for categories and carry query options for *where* in pagination
  const pagCategories = new Map<string, SelectionStatus>()
  pagCategories.set(msg('ADMIN.MERCHANTS.SHOW.CONFIRMED'), [true, false])
  pagCategories.set(msg('ADMIN.MERCHANTS.SHOW.UNCONFIRMED'), [false, false])
  pagCategories.set(msg('ADMIN.MERCHANTS.SHOW.DISABLED'), [undefined, true])
  pagCategories.set(msg('ADMIN.MERCHANTS.SHOW.ALL'), [undefined, undefined])

  const paginationCategories = useRef(pagCategories)
  const categoriesArr = Array.from(paginationCategories.current)

  //variables used to compare against current category for action buttons, disabled column
  //allows different buttons for different selections
  const i = paginationCategories.current.keys()

  const conf: string = i.next().value
  const unconf: string = i.next().value
  i.next()
  const all: string = i.next().value

  const showConfirmed = currentSelection === conf
  const showUnconfirmed = currentSelection === unconf
  const showAll = currentSelection === all

  const allowViewOrdersButtons = showConfirmed || showAll
  const allowConfirmationButtons = showUnconfirmed || showAll
  const allowDisableButtons = showConfirmed || showUnconfirmed || showAll

  const allowUndoConfirmationButtons = showConfirmed || showUnconfirmed || showAll
  const allowUndoDisableButtons = showAll

  //abort controller to abort requests and prevent memory leaks on unmount
  const controller = useRef(new AbortController())

  //opens modal with merchant data
  const openKYC = (index: number) => {
    changeUserMerchant(merchants[index])
    openDataModal()
  }

  //function to update admins merchant data with that of a selected user to view their orders
  const viewOrders = (index: number) => {
    changeUserMerchant(merchants[index])
    navigateTo('/orders')
  }

  const changeUserMerchant = (merchant: UserMerchantModel) => {
    setCurrentUser((user) => {
      if (!user) return undefined
      return {...user, merchants: [merchant]}
    })
  }

  const resetUserMerchants = useCallback(() => {
    setCurrentUser((user) => {
      if (!user) return undefined
      return {...user, merchants: backupUserMerchants.current}
    })
  }, [setCurrentUser])

  //manual pagination request that cancels active requests
  const refreshOrders = () => {
    controller.current.abort()
    controller.current = new AbortController()
    paginate(controller.current.signal)
  }

  //functions that send update request and change local data on success
  const updateDisplayedData = (merchantId: number, newStatus: MerchantStatus) => {
    const index = merchants.findIndex((m) => m.id === merchantId)
    const temp = {...merchants[index], ...newStatus}

    setMerchants((m) => {
      m[index] = temp
      return [...m]
    })
  }

  //function that sends request to update data and on success updates local state
  const updateStatusRequest = (merchantId: number, newStatus: MerchantStatus) => {
    setLoading(true)

    updateMerchantStatus(merchantId, controller.current.signal, newStatus)
      .then(() => {
        removeErrorMessage()
        updateDisplayedData(merchantId, newStatus)
      })
      .catch((err) => {
        if (err.message === 'canceled') console.log('canceled')
        const errMsg = getErrorMessage(err?.response?.status)
        setErrorMessage(errMsg)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateStatus = (
    merchantId: number,
    newStatus: MerchantStatus,
    targetMerchantName: string,
    action: string
  ) => {
    setCallback(() => () => updateStatusRequest(merchantId, newStatus))
    setTarget(targetMerchantName)
    setAction(action)
    openActionModal()
  }

  //function that returns current properties for pagination, based on category choice
  const getSelectionObject = useCallback(() => {
    const status = paginationCategories.current.get(currentSelection)
    const [isConfirmed, isDisabled] = status ?? [true, false]

    return {isConfirmed, isDisabled}
  }, [currentSelection])

  //changes selected column for pagination purposes
  const handleSelectionChange = (event: any) => {
    const all = msg('ADMIN.MERCHANTS.SHOW.ALL')
    setCurrentSelection(event.target.value)
    resetCurrentPage()
    if (currentSelection === all && currentColumn === 'isDisabled') setCurrentColumn('isConfirmed')
  }

  //function needed for pagination - ordering of records
  const getOrderingObject = useCallback((): MerchantResultOrder => {
    return {columnName: currentColumn, direction: direction}
  }, [currentColumn, direction])

  // functions that manipulate columns, pages, num of records
  const resetCurrentPage = () => {
    setPage(1)
  }

  const handleColumnChange = (column: MerchantColumn) => {
    if (currentColumn === column) {
      direction === 'asc' ? setDirection('desc') : setDirection('asc')
    } else {
      setCurrentColumn(column)
      setDirection('desc')
    }
    resetCurrentPage()
  }

  const handleTakeChange = (event: any) => {
    setTake(event.target.value)
    resetCurrentPage()
  }

  const handlePageChange = (value: number) => {
    setPage(value)
  }

  const checkIfDisabled = (arrowDirection: Arrow) => {
    if (page >= totalPages && arrowDirection === 'right') return true
    if (page === 1 && arrowDirection === 'left') return true

    return false
  }

  const handlePageNext = (arrowDirection: Arrow) => {
    if (checkIfDisabled(arrowDirection)) return false

    const nextPage = arrowDirection === 'right' ? page + 1 : page - 1
    setPage(nextPage)
  }

  const handlePageEdge = (arrowDirection: Arrow) => {
    if (checkIfDisabled(arrowDirection)) return false

    const nextPage = arrowDirection === 'right' ? totalPages : 1
    setPage(nextPage)
  }

  //func that returns pagination buttons
  const paginationButtons = () => {
    //change this to adjust the number of buttons to appear, includes current page
    const maxButtons = 5

    const buttonsOnEachSide = Math.round((maxButtons - 1) / 2)
    const arr = []
    const before = []

    for (let i = page - buttonsOnEachSide; i < page; i++) if (i > 0) before.push(i)
    for (let i = 0; i < before.length; i++) arr.push(before[i])
    arr.push(page)
    for (let i = page + 1; i < page + buttonsOnEachSide + 1 && i <= totalPages; i++) arr.push(i)

    return arr.map((number) => {
      const active = number === page ? 'active' : ''

      return (
        <button
          key={`page${number}`}
          className={`btn btn-icon btn-sm border-0 btn-light ms-2 my-1 ${active}`}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </button>
      )
    })
  }

  //pagination function, callback to prevent rerenders
  const paginate = useCallback(
    async (signal: AbortSignal) => {
      setLoading(true)

      const response = await getPaginatedMerchants(
        page,
        take,
        getOrderingObject(),
        signal,
        getSelectionObject()
      )
        .then((res) => {
          removeErrorMessage()
          return res.data
        })
        .catch((err) => {
          if (err.message === 'canceled') console.log('canceled')
          const errMsg = getErrorMessage(err?.response?.status)
          setErrorMessage(errMsg)
        })

      if (response) {
        const {merchants, pages, count} = response

        setMerchants(merchants)
        setTotalPages(pages)
        setTotalResults(count)
      }

      setLoading(false)
    },
    [getErrorMessage, getOrderingObject, getSelectionObject, page, take]
  )

  //hook that calls pagination on change and prevents memory leaks
  useEffect(() => {
    controller.current = new AbortController()

    paginate(controller.current.signal)

    return () => {
      controller.current.abort()
    }
  }, [paginate])

  //restore user merchants status on unmount
  useEffect(() => {
    return () => {
      resetUserMerchants()
    }
  }, [resetUserMerchants])

  return (
    <div className='card mb-5 mb-xl-8'>
      <MerchantActionModal
        show={showActionModal}
        closeModal={closeActionModal}
        callback={callback}
        action={action}
        targetName={target}
        intl={msg}
      />
      <MerchantDataModal
        show={showDataModal}
        closeModal={closeDataModal}
        merchants={currentUser?.merchants}
        intl={msg}
      />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{msg('ADMIN.MERCHANTS.TITLE')}</span>
        </h3>
        <div className='d-flex align-items-center fw-bold'>
          <span className='text-muted text-nowrap'>{msg('ADMIN.MERCHANTS.SHOP_SELECT.1')}</span>
          <select
            className='form-select form-select-sm border-0 bg-light ms-4 me-2'
            placeholder='Select company'
            onChange={handleSelectionChange}
          >
            {categoriesArr.map(([colName], index) => {
              return (
                <option key={`selectionOption${index}`} value={colName}>
                  {colName}
                </option>
              )
            })}
          </select>
          <span className='text-muted text-nowrap'>{msg('ADMIN.MERCHANTS.SHOP_SELECT.2')}</span>

          <button
            type='button'
            className='btn btn-sm bg-hover-light ms-3 me-1'
            data-kt-indicator={loading ? 'on' : 'off'}
            onClick={refreshOrders}
            disabled={loading}
          >
            <span className='indicator-label'>
              <span className='ki-solid ki-arrows-circle text-dark'></span>
            </span>
            <span className='indicator-progress'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </span>
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted fs-6'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}
                {colsArr.map(([displayName, col]) => {
                  if (col === 'isDisabled' && !showAll)
                    return <React.Fragment key={'empty'}></React.Fragment>

                  const textColor = currentColumn === col ? 'text-primary' : ''
                  const invisible = currentColumn === col ? '' : 'invisible'
                  const arrow = direction === 'asc' ? 'up' : 'down'
                  const hasStatus =
                    col === 'isConfirmed' || col === 'isDisabled' ? 'text-center w-125px' : ''

                  const idCol = col === 'id' ? 'w-50px' : ''
                  const taxCol = col === 'vatNumber' ? 'w-150px' : ''

                  return (
                    <th
                      key={`column ${col}`}
                      className={`ps-3 bg-hover-light user-select-none ${textColor} ${hasStatus} ${idCol} ${taxCol} text-nowrap`}
                      onClick={() => handleColumnChange(col)}
                    >
                      {displayName}{' '}
                      <i className={`ki ki-solid ki-arrow-${arrow} text-primary ${invisible}`}></i>
                    </th>
                  )
                })}

                <th className='pe-3 text-end min-w-400px w-400px'>
                  {msg('ADMIN.MERCHANTS.ACTIONS')}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {errorMessage && (
                <tr>
                  <td colSpan={6}>
                    <div className='alert alert-danger text-center'>
                      <div className='alert-text font-weight-bold'>{errorMessage}</div>
                    </div>
                  </td>
                </tr>
              )}
              {merchants.length > 0 &&
                merchants?.map((merchant, index) => {
                  const {id, companyName, vatNumber, isConfirmed, isDisabled, shops} = merchant

                  const hasShops = shops.length > 0

                  const confirmed = isConfirmed ? 'success' : 'warning'
                  const disabled = isDisabled ? 'danger' : 'success'

                  const showUndoConfirm = allowUndoConfirmationButtons && isConfirmed && !isDisabled
                  const hideUndoConfirm = showUndoConfirm ? '' : 'invisible'

                  const showUndoDisable = allowUndoDisableButtons && isDisabled
                  const hideUndoDisable = showUndoDisable ? '' : 'invisible'

                  const showViewOrder = allowViewOrdersButtons && isConfirmed && hasShops
                  const hideViewOrder = showViewOrder ? '' : 'invisible'

                  const showConfirm = allowConfirmationButtons && !isConfirmed && !isDisabled
                  const hideConfirm = showConfirm ? '' : 'invisible'

                  const showDisable = allowDisableButtons && !isDisabled
                  const hideDisable = showDisable ? '' : 'invisible'

                  const confirm = msg('ADMIN.MERCHANTS.ACTIONS.CONFIRM')
                  const unconfirm = msg('ADMIN.MERCHANTS.ACTIONS.UNCONFIRM')
                  const enable = msg('ADMIN.MERCHANTS.ACTIONS.ENABLE')
                  const disable = msg('ADMIN.MERCHANTS.ACTIONS.DISABLE')

                  return (
                    <tr key={`merchant ${index}`} className='text-nowrap'>
                      {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-13-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td> */}
                      <td>
                        <span className='text-dark fw-bold fs-6 ms-3'>{id}</span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold fs-6'>{companyName}</span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold fs-6'>{vatNumber}</span>
                      </td>
                      <td>
                        <span
                          className={`badge badge-light-${confirmed} w-100 justify-content-center`}
                        >
                          {isConfirmed ? yes : no}
                        </span>
                      </td>
                      {showAll && (
                        <td>
                          <span
                            className={`badge badge-light-${disabled} w-100 justify-content-center`}
                          >
                            {isDisabled ? yes : no}
                          </span>
                        </td>
                      )}
                      <td className='text-center'>
                        <div className='row no-wrap m-0'>
                          <div className='col min-w-90px px-0'>
                            <button
                              className={`btn btn-bg-light btn-sm w-100 px-0 text-hover-primary`}
                              onClick={() => openKYC(index)}
                            >
                              {'KYC'}
                            </button>
                          </div>
                          <div className='col min-w-90px px-0'>
                            <button
                              className={`btn btn-bg-light btn-sm w-100 px-0  text-hover-primary ${hideViewOrder}`}
                              onClick={() => viewOrders(index)}
                            >
                              {msg('ADMIN.MERCHANTS.ACTIONS.VIEW_ORDERS')}
                            </button>
                          </div>

                          <div className='col min-w-90px mx-1 px-0'>
                            {!showUndoConfirm ? (
                              <button
                                className={`btn btn-bg-light text-success btn-sm w-100 px-0 text-hover-primary ${hideConfirm}`}
                                onClick={() =>
                                  updateStatus(id, {isConfirmed: true}, companyName, confirm)
                                }
                                disabled={loading}
                              >
                                {msg('ADMIN.MERCHANTS.ACTIONS.CONFIRM')}
                              </button>
                            ) : (
                              <button
                                className={`btn btn-bg-light text-warning btn-sm w-100 px-0 text-hover-primary ${hideUndoConfirm}`}
                                onClick={() =>
                                  updateStatus(id, {isConfirmed: false}, companyName, unconfirm)
                                }
                                disabled={loading}
                              >
                                {msg('ADMIN.MERCHANTS.ACTIONS.UNCONFIRM')}
                              </button>
                            )}
                          </div>
                          <div className='col min-w-90px px-0'>
                            {!showUndoDisable ? (
                              <button
                                className={`btn btn-bg-light text-danger btn-sm w-100 px-0 text-hover-primary ${hideDisable}`}
                                onClick={() =>
                                  updateStatus(id, {isDisabled: true}, companyName, disable)
                                }
                                disabled={loading}
                              >
                                {msg('ADMIN.MERCHANTS.ACTIONS.DISABLE')}
                              </button>
                            ) : (
                              <button
                                className={`btn btn-bg-light text-warning btn-sm w-100 px-0 text-hover-primary ${hideUndoDisable}`}
                                onClick={() =>
                                  updateStatus(id, {isDisabled: false}, companyName, enable)
                                }
                                disabled={loading}
                              >
                                {msg('ADMIN.MERCHANTS.ACTIONS.ENABLE')}
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <div className='d-flex justify-content-between align-items-center flex-nowrap'>
          <span className='text-muted mt-1 fw-semibold fs-7 text-nowrap'>
            {totalResults} {msg('ADMIN.MERCHANTS.TOTAL')}
          </span>
          <div className='d-flex flex-wrap py-2 ms-3'>
            <button
              onClick={(e) => {
                handlePageEdge('left')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-double-left icon-xs'></i>
            </button>
            <button
              onClick={(e) => {
                handlePageNext('left')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-left icon-xs'></i>
            </button>

            {paginationButtons()}

            <button
              onClick={(e) => {
                handlePageNext('right')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-right icon-xs'></i>
            </button>
            <button
              onClick={(e) => {
                handlePageEdge('right')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-double-right icon-xs'></i>
            </button>
          </div>
          <div className='d-flex align-items-center py-3 ms-2'>
            <span className='text-muted text-nowrap'>{msg('ADMIN.MERCHANTS.RECORDS.1')}</span>
            <select
              className='form-select form-select-sm font-weight-bold ms-4 border-0 bg-light me-2'
              onChange={handleTakeChange}
            >
              {paginationOptions.map((val) => {
                return (
                  <option key={`option ${val}`} value={val}>
                    {val}
                  </option>
                )
              })}
            </select>
            <span className='text-muted'>{msg('ADMIN.MERCHANTS.RECORDS.2')}</span>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export {MerchantTable}
