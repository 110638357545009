import {Navigate, Route} from 'react-router-dom'

import {AuthPage, useAuth} from '../modules/auth'
import {UnverifiedUserPage} from '../modules/profile/components/unverified/UnverifiedUserPage'
import {PrivateRoutes} from './PrivateRoutes'

export default function SelectRoute() {
  const {currentUser} = useAuth()
  const {unverified = false, merchants = [], role = 'user'} = currentUser ?? {}
  /*
    <Navigate> paths to redirect to next step, * for catch all
    auth -> unverified email -> private: create company (merchant)
    Account setup steps don't have error pages, just redirects to main page.
  */
  //TODO: remove unverified routes entirely, only auth and private routes
  const authRoutes = (
    <>
      <Route path='auth/*' element={<AuthPage />} />
      <Route path='*' element={<Navigate to='/auth/login' />} />
    </>
  )
  const unverifiedRoutes = (
    <>
      <Route path='unverified/*' element={<UnverifiedUserPage />} />
      <Route path='*' element={<Navigate to='/unverified/dashboard' />} />
    </>
  )
  const privateRoutes = (
    <>
      <Route path='/*' element={<PrivateRoutes merchants={merchants} role={role} />} />
      <Route path='/' element={<Navigate to='/dashboard' />} />
    </>
  )

  if (!currentUser) return authRoutes
  if (unverified) return unverifiedRoutes
  else return privateRoutes
}
