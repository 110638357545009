/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../../../_metronic/layout/core'
import {useAuth} from '../../../auth'
import {AuthHeader} from '../../../auth/components/auth-header/AuthHeader'
import {resendVerificationEmail} from '../../../auth/core/_requests'
import {useErrorManagerForComponent} from '../../../errors/ErrorManager'

const UnverifiedUserDashboardPage: FC = () => {
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {currentUser, logout} = useAuth()
  const intl = useIntl()
  const getErrorMessage = useErrorManagerForComponent()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const resendEmail = async () => {
    try {
      if (!currentUser?.email) return false
      setHasErrors(undefined)
      setLoading(true)

      await resendVerificationEmail(currentUser.email)

      setErrorMessage('')
      setHasErrors(false)
    } catch (err: any) {
      const errMsg = getErrorMessage(err?.response?.status)
      setErrorMessage(errMsg)
      setHasErrors(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <AuthHeader />

      <div className='text-center'>
        <div className='fs-3'>
          <div className='text-info'>{msg('UNVERIFIED.DASHBOARD.STATUS')}</div>
          <div className='text-info'>{msg('UNVERIFIED.DASHBOARD.DIRECTIONS')}</div>
          <div className='mt-5 mb-5'>{msg('UNVERIFIED.DASHBOARD.RESEND.LABEL')}</div>
          {hasErrors === true && <div className='text-danger mt-6'>{errorMessage}</div>}
          {hasErrors !== false && (
            <button
              className='fs-4 btn btn-primary mt-5'
              disabled={loading}
              data-kt-indicator={loading ? 'on' : ''}
              onClick={resendEmail}
            >
              <span className='indicator-label'>{msg('UNVERIFIED.DASHBOARD.RESEND.SEND')}</span>
              <span className='indicator-progress'>
                {msg('APP.LOADING')}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          )}
          {hasErrors === false && (
            <div className='text-success mt-12'>
              {msg('UNVERIFIED.DASHBOARD.RESEND.CONFIRMATION')}
            </div>
          )}
          <div className='mt-5'>
            <button className='btn btn-link text-primary-hover fs-4 p-0' onClick={logout}>
              {msg('APP.SIGN_OUT')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const UnverifiedUserDashboard: FC = () => {
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{msg('MENU.DASHBOARD')}</PageTitle>
      <UnverifiedUserDashboardPage />
    </>
  )
}

export {UnverifiedUserDashboard}
