import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'

import {useErrorManagerForComponent} from '../../../errors/ErrorManager'
import {emailRegex} from '../../AuthRegex'
import {createPasswordReset} from '../../core/_requests'
import {AuthHeader} from '../auth-header/AuthHeader'
import {AuthSuccessMessage} from '../AuthSuccessMessage'

const initialValues = {
  email: '',
}

export function PasswordResetGenerator() {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const intl = useIntl()
  const getErrorMessage = useErrorManagerForComponent()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, msg('YUP.EMAIL_REGEX'))
      .min(3, msg('YUP.MINIMUM_3'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.EMAIL_REQUIRED')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        createPasswordReset(values.email)
          .then(({data: {result}}) => {
            setStatus('')
            setHasErrors(false)
          })
          .catch((err) => {
            const errMsg = getErrorMessage(err?.response?.status)
            setStatus(errMsg)

            setHasErrors(true)
          })
          .finally(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  if (hasErrors === false) return <AuthSuccessMessage i18nId='AUTH.RESET.GENERATOR.SUCCESS' />

  return (
    <>
      <AuthHeader />

      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>{msg('APP.FORGOT_PASSWORD')}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {msg('AUTH.RESET.GENERATOR.DIRECTIONS')}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger text-center'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{msg('APP.EMAIL')}</label>
          <input
            type='email'
            placeholder={msg('APP.EMAIL')}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary me-4'
            data-kt-indicator={loading ? 'on' : ''}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <span className='indicator-label'>{msg('APP.SUBMIT')}</span>
            <span className='indicator-progress'>
              {msg('APP.LOADING')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {msg('APP.CANCEL')}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
