import {Buffer} from 'buffer'

import {MerchantFile} from './_models'
import {allowedTypes} from './UseMerchantValidation'

export function getFileName(file: File | MerchantFile | undefined): string {
  if (!file) return 'file'

  //remove file extension
  const fileName = file.name.split('.')[0]
  return fileName
}

export function isAllowedExtension(file: File) {
  const extension = file.type

  return allowedTypes.includes(extension)
}

//countries allowed, same list on backend
export const supportedCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'GR',
  'ES',
  'FI',
  'FR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
]

/*** 2 letter country name ex. PL */
export function isSupportedCountry(country: string) {
  return supportedCountries.includes(country)
}

export async function convertFileToMerchantFile(file: File): Promise<MerchantFile> {
  const {name} = file
  const arr = await file.arrayBuffer()

  const buffer = Buffer.from(arr)
  const data = buffer.toJSON()

  return {name, data, uploadDate: '', size: file.size, type: file.type}
}

export function convertMerchantFileToFile(mFile: MerchantFile) {
  const buffer = Buffer.from(mFile.data.data)
  return new File([buffer], mFile.name, {type: allowedTypes[0]})
}

export function getFile(file: File | MerchantFile): File {
  //check if arg is File
  if (!('uploadDate' in file)) return file

  //else convert to File
  return convertMerchantFileToFile(file)
}

export async function getMerchantFile(file: File | MerchantFile): Promise<MerchantFile> {
  if (!file) return file
  //check if arg is MerchantFile, add extension on files from DB
  if ('uploadDate' in file) return {...file, name: file.name + '.pdf'}

  //else convert to MerchantFile
  return await convertFileToMerchantFile(file)
}

/** Takes size in Bytes
 * @returns Size string in KB and MB */
export function getFileSizeString(size: number) {
  const si = ['KB', 'MB']
  let i = 0

  size = size / 1024
  if (size >= 1024) {
    size /= 1024
    i++
  }

  return `${size.toFixed(2)} ${si[i]}`
}

export async function updateFileUploadDate(
  newDate: string,
  file: MerchantFile | File
): Promise<MerchantFile> {
  const merchantFile = await getMerchantFile(file)

  return {...merchantFile, uploadDate: newDate}
}

export function openFile(url: string, target: string) {
  window.open(url, target)
}

export function revokeUrl(url: string) {
  window.URL.revokeObjectURL(url)
}
