/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {ShopModel, UserMerchantModel, UserRole} from '../../../auth'
import {useErrorManagerForComponent} from '../../../errors/ErrorManager'
import {defaultPaginationAmount, paginationOptions} from '../general/pagination'
import getDateString from './_dateFormat'
import {Direction, Order, OrderColumn, ResultOrder} from './_models'
import {getPaginatedOrders} from './_requests'

type Props = {
  merchants: UserMerchantModel[]
  role: UserRole
}

type Arrow = 'left' | 'right'

export default function OrderTable({merchants, role}: Props) {
  const currentMerchant = merchants[0]
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  //default ordering column
  const defaultColumn: OrderColumn = 'createdDate'
  const [currentColumn, setCurrentColumn] = useState<OrderColumn>(defaultColumn)

  const [currentShop, setCurrentShop] = useState<ShopModel>(currentMerchant.shops[0])
  const [orders, setOrders] = useState<Order[]>()
  const [take, setTake] = useState<number>(defaultPaginationAmount)
  const [page, setPage] = useState<number>(1)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [direction, setDirection] = useState<Direction>('desc')

  const [loading, setLoading] = useState<boolean>(true)

  //error handling | ref used because function doesn't change, to not cause rerenders
  const [errorMessage, setErrorMessage] = useState<string>('')
  const errorManager = useRef(useErrorManagerForComponent('OrderTable'))
  const getErrorMessage = errorManager.current
  const removeErrorMessage = () => {
    setErrorMessage('')
  }

  //map that contains column display names and their db field names
  const cols = new Map<string, OrderColumn>()
  cols.set(msg('USER.ORDERS.ORDER_NUMBER'), 'orderNumber')
  cols.set(msg('USER.ORDERS.AMOUNT'), 'amount')
  cols.set(msg('USER.ORDERS.BASE_CURRENCY'), 'baseCurrency')
  cols.set(msg('USER.ORDERS.CREATED_DATE'), 'createdDate')
  cols.set(msg('USER.ORDERS.STATUS'), 'status')

  const colsArr = Array.from(cols)

  //abort controller to abort requests and prevent memory leaks on unmount
  const controller = useRef(new AbortController())

  const refreshOrders = () => {
    controller.current.abort()
    controller.current = new AbortController()
    paginate(controller.current.signal)
  }

  //function for column ordering of data, callback to prevent rerenders
  const getOrderingObject = useCallback((): ResultOrder => {
    return {columnName: currentColumn, direction: direction}
  }, [currentColumn, direction])

  const resetCurrentPage = () => {
    setPage(1)
  }

  const handleColumnChange = (column: OrderColumn) => {
    if (currentColumn === column) {
      direction === 'asc' ? setDirection('desc') : setDirection('asc')
    } else {
      setCurrentColumn(column)
      setDirection('desc')
    }
    resetCurrentPage()
  }

  const handleShopChange = (event: any) => {
    setCurrentShop(currentMerchant.shops[event.target.value])
    resetCurrentPage()
  }

  const handleTakeChange = (event: any) => {
    setTake(event.target.value)
    resetCurrentPage()
  }

  const handlePageChange = (value: number) => {
    setPage(value)
  }

  const checkIfDisabled = (arrowDirection: Arrow) => {
    if (page >= totalPages && arrowDirection === 'right') return true
    if (page === 1 && arrowDirection === 'left') return true

    return false
  }

  const handlePageNext = (arrowDirection: Arrow) => {
    if (checkIfDisabled(arrowDirection)) return false

    const nextPage = arrowDirection === 'right' ? page + 1 : page - 1
    setPage(nextPage)
  }

  const handlePageEdge = (arrowDirection: Arrow) => {
    if (checkIfDisabled(arrowDirection)) return false

    const nextPage = arrowDirection === 'right' ? totalPages : 1
    setPage(nextPage)
  }

  //func that returns pagination buttons
  const paginationButtons = () => {
    //change this to adjust the number of buttons to appear, includes current page
    const maxButtons = 5

    const buttonsOnEachSide = Math.round((maxButtons - 1) / 2)
    const arr = []
    const before = []

    for (let i = page - buttonsOnEachSide; i < page; i++) if (i > 0) before.push(i)
    for (let i = 0; i < before.length; i++) arr.push(before[i])
    arr.push(page)
    for (let i = page + 1; i < page + buttonsOnEachSide + 1 && i <= totalPages; i++) arr.push(i)

    return arr.map((number) => {
      const active = number === page ? 'active' : ''

      return (
        <button
          key={`page${number}`}
          className={`btn btn-icon btn-sm border-0 btn-light ms-2 my-1 ${active}`}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </button>
      )
    })
  }

  //pagination function, callback to prevent rerenders
  const paginate = useCallback(
    async (signal: AbortSignal) => {
      const {id: shopId} = currentShop
      const {id: merchantId} = currentMerchant

      setLoading(true)

      const response = await getPaginatedOrders(
        shopId,
        merchantId,
        page,
        take,
        getOrderingObject(),
        signal
      )
        .then((res) => {
          removeErrorMessage()
          return res.data
        })
        .catch((err) => {
          if (err.message === 'canceled') console.log('canceled')
          const errMsg = getErrorMessage(err?.response?.status)
          setErrorMessage(errMsg)
        })

      if (response) {
        const {orders, pages, count} = response

        setOrders(orders)
        setTotalPages(pages)
        setTotalResults(count)
      }

      setLoading(false)
    },
    [currentMerchant, currentShop, getErrorMessage, getOrderingObject, page, take]
  )

  //hook that calls pagination on change and prevents memory leaks
  useEffect(() => {
    controller.current = new AbortController()

    paginate(controller.current.signal)

    return () => {
      controller.current.abort()
    }
  }, [paginate])

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {msg('USER.ORDERS.TITLE')} {role === 'admin' && `- ${currentMerchant.companyName}`}
          </span>
        </h3>
        {/* <div className='d-flex align-items-center'>
          <select
            className='form-select border-0 bg-light'
            placeholder='Select company'
            onChange={handleMerchantChange}
          >
            {merchants.map((merchant, index) => {
              return (
                <option key={`merchant${index}`} value={index}>
                  {merchant.name}
                </option>
              )
            })}
          </select>
        </div> */}
        <div className='d-flex align-items-center fw-bold text-nowrap'>
          <span className='text-muted'>{msg('USER.ORDERS.SHOP_SELECT.1')}</span>
          <select
            className='form-select form-select-sm border-0 bg-light ms-4 me-2'
            placeholder='Select shop'
            onChange={handleShopChange}
            disabled={currentMerchant.shops.length === 1}
          >
            {currentMerchant.shops.map((shop, index) => {
              return (
                <option key={`shop${index}`} value={index}>
                  {shop.name}
                </option>
              )
            })}
          </select>
          <span className='text-muted'>{msg('USER.ORDERS.SHOP_SELECT.2')}</span>
          <button
            type='button'
            className='btn btn-sm bg-hover-light ms-3 me-1'
            data-kt-indicator={loading ? 'on' : 'off'}
            onClick={refreshOrders}
            disabled={loading}
          >
            <span className='indicator-label'>
              <span className='ki-solid ki-arrows-circle text-dark'></span>
            </span>
            <span className='indicator-progress'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </span>
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted fs-6'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}
                {colsArr.map(([displayName, colName]) => {
                  const textColor = currentColumn === colName ? 'text-primary' : ''
                  const invisible = currentColumn === colName ? '' : 'invisible'
                  const arrow = direction === 'asc' ? 'up' : 'down'

                  const isStatus = colName === 'status' ? 'w-150px min-w-100px' : ''

                  return (
                    <th
                      key={`column ${colName}`}
                      className={`ps-3 bg-hover-light user-select-none ${textColor} ${isStatus} text-nowrap`}
                      onClick={() => handleColumnChange(colName)}
                    >
                      {displayName}{' '}
                      <i className={`ki ki-solid ki-arrow-${arrow} text-primary ${invisible}`}></i>
                    </th>
                  )
                })}
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>
              {errorMessage && (
                <tr>
                  <td colSpan={6}>
                    <div className='alert alert-danger text-center'>
                      <div className='alert-text font-weight-bold'>{errorMessage}</div>
                    </div>
                  </td>
                </tr>
              )}
              {orders !== undefined &&
                orders.length > 0 &&
                orders?.map((order, index) => {
                  const {orderNumber, amount, baseCurrency, createdDate, status} = order
                  const date = getDateString(createdDate)

                  const s = status === 'OPEN' ? 'primary' : 'success'

                  const intStatus =
                    status === 'FULFILLED'
                      ? msg('USER.ORDERS.STATUS.FULFILLED')
                      : msg('USER.ORDERS.STATUS.OPEN')

                  return (
                    <tr key={`order${index}`}>
                      {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-13-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td> */}
                      <td>
                        <span className='text-dark fw-bold fs-6 ms-3'>{orderNumber}</span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>
                          {parseFloat(amount).toFixed(2)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{baseCurrency}</span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold d-block mb-1 fs-6 min-w-125px'>
                          {date}
                        </span>
                      </td>
                      <td>
                        <span className={`badge badge-light-${s} w-100 justify-content-center`}>
                          {intStatus}
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <div className='d-flex justify-content-between align-items-center flex-nowrap'>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {totalResults} {msg('USER.ORDERS.TOTAL')}
          </span>
          <div className='d-flex flex-wrap py-2 ms-3'>
            <button
              onClick={(e) => {
                handlePageEdge('left')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-double-left icon-xs'></i>
            </button>
            <button
              onClick={(e) => {
                handlePageNext('left')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-left icon-xs'></i>
            </button>

            {paginationButtons()}

            <button
              onClick={(e) => {
                handlePageNext('right')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-right icon-xs'></i>
            </button>
            <button
              onClick={(e) => {
                handlePageEdge('right')
                e.currentTarget.blur()
              }}
              className='btn btn-icon btn-sm btn-light ms-2 my-1'
            >
              <i className='ki ki-solid ki-double-right icon-xs'></i>
            </button>
          </div>
          <div className='d-flex align-items-center py-3 ms-2'>
            <span className='text-muted'>{msg('USER.ORDERS.RECORDS.1')}</span>
            <select
              className='form-select form-select-sm font-weight-bold ms-4 border-0 bg-light me-2 w-75px'
              onChange={handleTakeChange}
            >
              {paginationOptions.map((val) => {
                return (
                  <option key={`option ${val}`} value={val}>
                    {val}
                  </option>
                )
              })}
            </select>
            <span className='text-muted'>{msg('USER.ORDERS.RECORDS.2')}</span>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export {OrderTable}
