import {useIntl} from 'react-intl'

const supportedComponents = [
  '',
  'Login',
  'Registration',
  'EmailVerification',
  'PasswordResetter',
  'CreateMerchant',
  'UpdateMerchant',
  'MerchantTable',
  'KYCModal',
  'OrderTable',
  'OrdersPage',
] as const
type componentName = typeof supportedComponents[number]

const convertToStringOrUndefined = (
  errorStatus: string | number | undefined
): string | undefined => {
  return errorStatus ? errorStatus + '' : undefined
}

/**
 *
 * @param componentName Choose a component name that is supported
 * @returns A function that takes in error codes and returns appropriate messages
 * @description  Uses i18n to return locale specific messages based on error codes
 */

export function useErrorManagerForComponent(componentName: componentName = '') {
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const ordersPageMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const noMerchantMessage = msg('USER.ORDERS_PAGE.ERROR.NO_MERCHANT')
    const noShopsMessage = msg('USER.ORDERS_PAGE.ERROR.NO_SHOPS')
    const notConfirmedMessage = msg('USER.ORDERS_PAGE.ERROR.NOT_CONFIRMED')

    switch (errorCode) {
      case '1':
        return noMerchantMessage
      case '2':
        return noShopsMessage
      case '3':
        return notConfirmedMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const orderTableMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const sessionExpiredMessage = msg('APP.ERROR.SESSION_EXPIRED')

    switch (errorCode) {
      case '401':
        return sessionExpiredMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const kycModalMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const sessionExpiredMessage = msg('APP.ERROR.SESSION_EXPIRED')

    switch (errorCode) {
      case '401':
        return sessionExpiredMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const merchantTableMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const sessionExpiredMessage = msg('APP.ERROR.SESSION_EXPIRED')

    switch (errorCode) {
      case '401':
        return sessionExpiredMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const updateMerchantMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const sessionExpiredMessage = msg('APP.ERROR.SESSION_EXPIRED')
    const notFoundMessage = msg('USER.MERCHANT_UPDATE.ERROR.NOT_FOUND')
    const cannotUpdateMessage = msg('USER.MERCHANT_UPDATE.ERROR.CANNOT_UPDATE')
    const vatAndEmailTakenMessage = msg('USER.MERCHANT.ERROR.VAT_AND_EMAIL_TAKEN')
    const vatNumberTakenMessage = msg('USER.MERCHANT.ERROR.VATNUMBER_TAKEN')
    const companyEmailTakenMessage = msg('USER.MERCHANT.ERROR.COMPANY_EMAIL_TAKEN')
    const unchangedValuesMessage = msg('USER.MERCHANT_UPDATE.ERROR.UNCHANGED_VALUES')

    switch (errorCode) {
      case '401':
        return sessionExpiredMessage
      case '404':
        return notFoundMessage
      case '409':
        return cannotUpdateMessage
      case '1':
        return vatAndEmailTakenMessage
      case '2':
        return vatNumberTakenMessage
      case '3':
        return companyEmailTakenMessage
      case '4':
        return unchangedValuesMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const createMerchantMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const sessionExpiredMessage = msg('APP.ERROR.SESSION_EXPIRED')
    const vatNumberTakenMessage = msg('USER.MERCHANT.ERROR.VATNUMBER_TAKEN')
    const companyEmailTakenMessage = msg('USER.MERCHANT.ERROR.COMPANY_EMAIL_TAKEN')
    const vatAndEmailTakenMessage = msg('USER.MERCHANT.ERROR.VAT_AND_EMAIL_TAKEN')

    switch (errorCode) {
      case '401':
        return sessionExpiredMessage
      case '1':
        return vatAndEmailTakenMessage
      case '2':
        return vatNumberTakenMessage
      case '3':
        return companyEmailTakenMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const passwordResetterMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const badLinkMessage = msg('AUTH.RESET.RESETTER.ERROR.BAD_LINK')

    switch (errorCode) {
      case '401':
        return badLinkMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const emailVerificationMessages = (errorStatus: string | number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const badTokenMessage = msg('AUTH.EMAIL_VERIFICATION.ERROR.BAD_TOKEN')
    const alreadyVerifiedMessage = msg('AUTH.EMAIL_VERIFICATION.ERROR.ALREADY_VERIFIED')

    switch (errorCode) {
      case '401':
        return badTokenMessage
      case '409':
        return alreadyVerifiedMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const registrationMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const emailTakenMessage = msg('AUTH.REGISTER.ERROR.EMAIL_TAKEN')

    switch (errorCode) {
      case '409':
        return emailTakenMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const loginMessages = (errorStatus: number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const badCredentialsMessage = msg('AUTH.LOGIN.ERROR.BAD_CREDENTIALS')
    const accountSuspendedMessage = msg('AUTH.LOGIN.ERROR.ACCOUNT_SUSPENDED')

    switch (errorCode) {
      case '401':
        return badCredentialsMessage
      case '403':
        return accountSuspendedMessage
      default:
        return defaultMessages(errorCode)
    }
  }

  const defaultMessages = (errorStatus: string | number | undefined): string => {
    const errorCode = convertToStringOrUndefined(errorStatus)

    const defaultMessage = msg('APP.ERROR.DEFAULT')
    const noConnectionMessage = msg('APP.ERROR.NO_CONNECTION')

    switch (errorCode) {
      case undefined:
        return noConnectionMessage
      default:
        return defaultMessage
    }
  }

  switch (componentName) {
    case 'Login':
      return loginMessages
    case 'Registration':
      return registrationMessages
    case 'EmailVerification':
      return emailVerificationMessages
    case 'PasswordResetter':
      return passwordResetterMessages
    case 'CreateMerchant':
      return createMerchantMessages
    case 'UpdateMerchant':
      return updateMerchantMessages
    case 'MerchantTable':
      return merchantTableMessages
    case 'KYCModal':
      return kycModalMessages
    case 'OrderTable':
      return orderTableMessages
    case 'OrdersPage':
      return ordersPageMessages
    default:
      return defaultMessages
  }
}
