import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'

import {useErrorManagerForComponent} from '../../errors/ErrorManager'
import {getUserByToken, verifyEmail} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {AuthSuccessMessage} from './AuthSuccessMessage'

export function EmailVerification() {
  const {auth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const intl = useIntl()
  const getErrorMessage = useRef(useErrorManagerForComponent('EmailVerification'))
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token') || ''

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  useEffect(() => {
    if (!token) return setErrorMessage(getErrorMessage.current(401))

    async function updateUserData(api_token: string) {
      const {data} = await getUserByToken(api_token)
      if (data) setCurrentUser(data)
    }

    setLoading(true)

    setTimeout(() => {
      verifyEmail(token)
        .then(({data: {result}}) => {
          if (auth && auth.api_token) updateUserData(auth.api_token)

          setHasErrors(false)
        })
        .catch((err) => {
          const errMsg = getErrorMessage.current(err?.request?.status)
          setErrorMessage(errMsg)
          setHasErrors(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }, 1000)
  }, [auth, getErrorMessage, setCurrentUser, token])

  if (hasErrors === false) return <AuthSuccessMessage i18nId='AUTH.EMAIL_VERIFICATION.SUCCESS' />

  return (
    <div className='d-flex flex-center'>
      <h3 data-kt-indicator={loading ? 'on' : ''}>
        <span className='indicator-progress'>
          {msg('AUTH.EMAIL_VERIFICATION.WAITING')}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </h3>
      {hasErrors && <h3 className='text-danger'>{errorMessage}</h3>}
    </div>
  )
}
