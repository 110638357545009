import {useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'

export default function SettingsNavigation() {
  const location = useLocation()
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  return (
    <div className='card  shadow-sm mb-5 mb-xl-10'>
      <div className='card-body d-flex align-items'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/settings/company' && 'active')
              }
              to='/settings/company'
            >
              {msg('USER.SETTINGS.COMPANY')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/settings/shops' && 'active')
              }
              to='/settings/shops'
            >
              {msg('USER.SETTINGS.SHOPS')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
