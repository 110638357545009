import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'

import {useAuth} from '../../../auth'
import {emailRegex} from '../../../auth/AuthRegex'
import {useErrorManagerForComponent} from '../../../errors/ErrorManager'
import {getFileName, isAllowedExtension} from './_functions'
import {MerchantFileName} from './_models'
import {createMerchant} from './_requests'
import MerchantAttachments from './MerchantAttachments'
import useMerchantValidation, {allowedTypes} from './UseMerchantValidation'

const initialValues = {
  companyName: '',
  vatNumber: '',
  companyEmail: '',
  companyPhone: '',
  companyAddress: '',
  companyType: '',
  activityType: '',
  foundingDocument: undefined,
  capitalSource: undefined,
}

type InitialType = {
  companyName: string
  vatNumber: string
  companyEmail: string
  companyPhone: string
  companyAddress: string
  companyType: string
  activityType: string
  foundingDocument: File | undefined
  capitalSource: File | undefined
}

export default function CreateMerchantForm() {
  const {setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const getErrorMessage = useErrorManagerForComponent('CreateMerchant')
  const [otherIsSelected, setOtherIsSelected] = useState<boolean>(false)
  const intl = useIntl()

  //abort controller to abort requests and prevent memory leaks on unmount
  const controller = useRef(new AbortController())

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  //container element reference
  const thisContainer = useRef<HTMLDivElement>(null)

  const scrollUp = () => {
    const coords = thisContainer.current?.getBoundingClientRect()
    if (coords) {
      window.scrollTo(coords.left, coords.top + window.scrollY)
    }
  }

  const {validateFile, validatePhoneNumber, validateTaxNumber, getErrorCode} =
    useMerchantValidation()

  const merchantSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(1, msg('YUP.MINIMUM_1'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.COMPANY_NAME_REQUIRED')),
    vatNumber: validateTaxNumber(),
    companyEmail: Yup.string()
      .matches(emailRegex, msg('YUP.EMAIL_REGEX'))
      .min(3, msg('YUP.MINIMUM_3'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.EMAIL_REQUIRED')),
    companyPhone: validatePhoneNumber(),
    companyAddress: Yup.string()
      .max(200, msg('YUP.MAXIMUM_200'))
      .required(msg('YUP.COMPANY_ADDRESS_REQUIRED')),
    companyType: Yup.string()
      .min(1, msg('YUP.MINIMUM_1'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .not(['Other'], msg('YUP.COMPANY_TYPE_REQUIRED'))
      .required(msg('YUP.COMPANY_TYPE_REQUIRED')),
    activityType: Yup.string()
      .min(1, msg('YUP.MINIMUM_1'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.ACTIVITY_TYPE_REQUIRED')),
    foundingDocument: validateFile('foundingDocument'),
    capitalSource: validateFile('capitalSource'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: merchantSchema,
    onSubmit: async (values: InitialType, {setStatus, setSubmitting}) => {
      const {foundingDocument, capitalSource} = values
      if (!foundingDocument || !capitalSource) return
      setLoading(true)

      try {
        //extract file names
        const foundingDocumentName = getFileName(foundingDocument)
        const capitalSourceName = getFileName(capitalSource)

        const {data: user} = await createMerchant(
          {...values, foundingDocument, capitalSource, foundingDocumentName, capitalSourceName},
          controller.current.signal
        )
        setCurrentUser(user)
      } catch (err: any) {
        const code = getErrorCode(err?.response?.status, err?.response?.data?.message)

        const errMsg = getErrorMessage(code)
        setStatus(errMsg)
        scrollUp()
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: MerchantFileName
  ) => {
    if (e.target.files) {
      const file = e.target.files[0]

      //make sure type is allowed before saving file into memory
      if (!isAllowedExtension(file)) return

      formik.setFieldValue(fieldName, file, true)

      formik.setFieldTouched(fieldName, true, false)
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    formik.setFieldValue('companyType', value, true)
    formik.setFieldTouched('companyType', true, false)

    setOtherIsSelected(value === 'Other')
  }

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    formik.setFieldValue('companyType', value, true)
    formik.setFieldTouched('companyType', true, false)
  }

  const handleFileRemove = (target: MerchantFileName) => {
    //set value and validate field
    formik.setFieldValue(target, undefined, true)
    //touch field to display validation status
    formik.setFieldTouched(target, true, false)
  }

  //hook that manages request aborting to prevent memory leaks
  useEffect(() => {
    controller.current = new AbortController()

    return () => {
      controller.current.abort()
    }
  }, [])

  const {foundingDocument, capitalSource} = formik.values

  return (
    <div ref={thisContainer} className='card mw-700px mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <form
          className='form w-100 '
          onSubmit={formik.handleSubmit}
          noValidate
          id='create-merchant-form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>{msg('USER.MERCHANT.SETUP')}</h1>
            <div className='text-gray-500 text-center fw-semibold fs-6'>
              {msg('USER.MERCHANT.DESCRIPTION')}
            </div>
          </div>
          {/* begin::Heading */}

          {formik.status && (
            <div id='create-merchant-status' className='mb-lg-15 alert alert-danger text-center'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Row */}
          <div className='row mb-8'>
            <div className='col'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {msg('USER.MERCHANT.COMPANY_NAME')}
              </label>
              <input
                id='create-merchant-company-name'
                {...formik.getFieldProps('companyName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.companyName && formik.errors.companyName},
                  {
                    'is-valid': formik.touched.companyName && !formik.errors.companyName,
                  }
                )}
                type='text'
                name='companyName'
                autoComplete='off'
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col'>
              <label className='form-label fw-bolder text-dark text-nowrap fs-6'>
                {msg('USER.MERCHANT.VAT_NUMBER')}
              </label>
              <input
                id='create-merchant-vat-number'
                placeholder='PL1234567890'
                maxLength={17}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('vatNumber')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.vatNumber && formik.errors.vatNumber,
                  },
                  {
                    'is-valid': formik.touched.vatNumber && !formik.errors.vatNumber,
                  }
                )}
                name='vatNumber'
              />
              {formik.touched.vatNumber && formik.errors.vatNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.vatNumber}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row mb-10'>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.COMPANY_EMAIL')}
              </label>
              <input
                id='create-merchant-company-email'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('companyEmail')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.companyEmail && formik.errors.companyEmail,
                  },
                  {
                    'is-valid': formik.touched.companyEmail && !formik.errors.companyEmail,
                  }
                )}
                name='companyEmail'
              />
              {formik.touched.companyEmail && formik.errors.companyEmail && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyEmail}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.COMPANY_PHONE')}
              </label>
              <input
                id='create-merchant-company-phone'
                placeholder='+48 888 888 888'
                type='tel'
                maxLength={15}
                autoComplete='off'
                {...formik.getFieldProps('companyPhone')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.companyPhone && formik.errors.companyPhone,
                  },
                  {
                    'is-valid': formik.touched.companyPhone && !formik.errors.companyPhone,
                  }
                )}
                name='companyPhone'
              />
              {formik.touched.companyPhone && formik.errors.companyPhone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyPhone}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row mb-10'>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.COMPANY_ADDRESS')}
              </label>
              <textarea
                id='create-merchant-company-address'
                rows={1}
                maxLength={200}
                autoComplete='off'
                {...formik.getFieldProps('companyAddress')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.companyAddress && formik.errors.companyAddress,
                  },
                  {
                    'is-valid': formik.touched.companyAddress && !formik.errors.companyAddress,
                  }
                )}
                name='companyAddress'
              />
              {formik.touched.companyAddress && formik.errors.companyAddress && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyAddress}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.ACTIVITY_TYPE')}
              </label>
              <input
                id='create-merchant-activity-type'
                type='text'
                placeholder={msg('USER.MERCHANT.PLACEHOLDER.ACTIVITY_TYPE')}
                autoComplete='off'
                {...formik.getFieldProps('activityType')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.activityType && formik.errors.activityType,
                  },
                  {
                    'is-valid': formik.touched.activityType && !formik.errors.activityType,
                  }
                )}
                name='activityType'
              />
              {formik.touched.activityType && formik.errors.activityType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.activityType}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row mb-10'>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.COMPANY_TYPE')}
              </label>
              <select
                id='create-merchant-company-type'
                autoComplete='off'
                onChange={(e) => handleSelectChange(e)}
                className={clsx(
                  'form-select ',
                  {
                    'is-invalid': formik.touched.companyType && formik.errors.companyType,
                  },
                  {
                    'is-valid': formik.touched.companyType && !formik.errors.companyType,
                  }
                )}
                name='companyType'
              >
                <option defaultChecked value={''}>
                  {msg('USER.MERCHANT.COMPANY_TYPES.PLACEHOLDER')}
                </option>
                <option value={'Ltd'}>{msg('USER.MERCHANT.COMPANY_TYPES.LTD')}</option>
                <option value={'Stock company'}>{msg('USER.MERCHANT.COMPANY_TYPES.STOCK')}</option>
                <option value={'Self-employment'}>{msg('USER.MERCHANT.COMPANY_TYPES.SELF')}</option>
                <option value={'Other'}>{msg('USER.MERCHANT.COMPANY_TYPES.OTHER')}</option>
              </select>
              {formik.touched.companyType && formik.errors.companyType && !otherIsSelected && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyType}</span>
                  </div>
                </div>
              )}
            </div>
            {otherIsSelected && (
              <div className='col'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  {msg('USER.MERCHANT.COMPANY_TYPE.OTHER')}
                </label>
                <input
                  id='create-merchant-company-type-other'
                  autoComplete='off'
                  className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.companyType && formik.errors.companyType,
                    },
                    {
                      'is-valid': formik.touched.companyType && !formik.errors.companyType,
                    }
                  )}
                  name='companyType'
                  placeholder={msg('USER.MERCHANT.PLACEHOLDER.COMPANY_TYPE')}
                  onChange={(e) => handleOtherInputChange(e)}
                />
                {formik.touched.companyType && formik.errors.companyType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.companyType}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row mb-10'>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.FOUNDING_DOCUMENT')}
              </label>
              <input
                id='create-merchant-founding-document'
                type='file'
                autoComplete='off'
                onChange={(e) => handleFileChange(e, 'foundingDocument')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.foundingDocument && formik.errors.foundingDocument,
                  },
                  {
                    'is-valid': formik.touched.foundingDocument && !formik.errors.foundingDocument,
                  }
                )}
                name='foundingDocument'
                accept={allowedTypes[0]}
                hidden
              />
              <label
                htmlFor='create-merchant-founding-document'
                className={clsx(
                  'py-3 btn btn-secondary-dark btn-outline bg-transparent btn-active-light rounded text-primary text-center w-100',
                  {
                    'btn-outline-danger':
                      formik.touched.foundingDocument && formik.errors.foundingDocument,
                  },
                  {
                    'btn-outline-success':
                      formik.touched.foundingDocument && !formik.errors.foundingDocument,
                  }
                )}
              >
                {msg('USER.MERCHANT.ADD_FOUNDING_DOCUMENTS')}
              </label>
              {formik.touched.foundingDocument && formik.errors.foundingDocument && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      <>{formik.errors.foundingDocument}</>
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {msg('USER.MERCHANT.CAPITAL_SOURCE')}
              </label>
              <input
                id='create-merchant-capital-source'
                type='file'
                autoComplete='off'
                onChange={(e) => handleFileChange(e, 'capitalSource')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.capitalSource && formik.errors.capitalSource,
                  },
                  {
                    'is-valid': formik.touched.capitalSource && !formik.errors.capitalSource,
                  }
                )}
                name='capitalSource'
                accept={allowedTypes[0]}
                hidden
              />
              <label
                htmlFor='create-merchant-capital-source'
                className={clsx(
                  'py-3 btn btn-secondary-dark btn-outline bg-transparent btn-active-light rounded text-primary text-center w-100',
                  {
                    'btn-outline-danger':
                      formik.touched.capitalSource && formik.errors.capitalSource,
                  },
                  {
                    'btn-outline-success':
                      formik.touched.capitalSource && !formik.errors.capitalSource,
                  }
                )}
              >
                {msg('USER.MERCHANT.ADD_CAPITAL_SOURCE')}
              </label>
              {formik.touched.capitalSource && formik.errors.capitalSource && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      <>{formik.errors.capitalSource}</>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Row group */}

          {/* begin::Files */}
          <div>
            <MerchantAttachments
              foundingDocument={foundingDocument}
              capitalSource={capitalSource}
              handleRemove={handleFileRemove}
            />
          </div>
          {/* end::Files */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='create-merchant-submit'
              className='btn btn-primary'
              data-kt-indicator={loading ? 'on' : ''}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>{msg('APP.SUBMIT')}</span>
              <span className='indicator-progress'>
                {msg('APP.LOADING')}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </div>
  )
}
