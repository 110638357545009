import {useAuth} from '../../../auth'
import {useErrorManagerForComponent} from '../../../errors/ErrorManager'
import {OrderTable} from './OrderTable'

export default function OrdersPage() {
  const {currentUser} = useAuth()

  const {merchants = [], role = 'user'} = currentUser ?? {}

  const {shops = [], isConfirmed = false} = merchants[0] ?? []
  const length = merchants.length

  let errCode = 0
  if (length < 1) errCode = 1
  else if (shops.length < 1) errCode = 2
  else if (!isConfirmed) errCode = 3

  const errorManager = useErrorManagerForComponent('OrdersPage')
  const errorMessage = errorManager(errCode)

  if (length < 1 || shops.length < 1 || !isConfirmed)
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>{errorMessage}</h1>
          </div>
        </div>
      </div>
    )

  return (
    <>
      <OrderTable merchants={merchants} role={role} />
    </>
  )
}
