import axios from 'axios'

import {UserModel} from '../../../auth'
import {
  CreateMerchantProps,
  MerchantResultOrder,
  MerchantStatus,
  PaginatedMerchantsDto,
  PartialMerchantFiles,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const MERCHANT_URL = `${API_URL}/merchants`
export const MERCHANT_PAGINATION_URL = `${MERCHANT_URL}/paginated`
export const MERCHANT_STATUS_URL = `${MERCHANT_URL}/status`

const fileConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

export const createMerchant = (
  {
    companyName,
    vatNumber,
    companyEmail,
    companyPhone,
    companyAddress,
    companyType,
    activityType,
    foundingDocument,
    foundingDocumentName,
    capitalSource,
    capitalSourceName,
  }: CreateMerchantProps,
  signal: AbortSignal
) => {
  //files need to be sent in a formdata
  const formData = new FormData()

  formData.append('companyName', companyName)
  formData.append('vatNumber', vatNumber)
  formData.append('companyEmail', companyEmail)
  formData.append('companyPhone', companyPhone)
  formData.append('companyAddress', companyAddress)
  formData.append('companyType', companyType)
  formData.append('activityType', activityType)
  formData.append('foundingDocumentName', foundingDocumentName)
  formData.append('capitalSourceName', capitalSourceName)

  formData.append('foundingDocument', foundingDocument)
  formData.append('capitalSource', capitalSource)

  return axios.post<UserModel>(MERCHANT_URL, formData, {...fileConfig, signal})
}

export function updateMerchant(
  id: number,
  {
    companyName,
    vatNumber,
    companyEmail,
    companyPhone,
    companyAddress,
    companyType,
    activityType,
    foundingDocument,
    foundingDocumentName,
    capitalSource,
    capitalSourceName,
  }: CreateMerchantProps,
  signal: AbortSignal
) {
  //files need to be sent in a formdata
  const formData = new FormData()

  formData.append('companyName', companyName)
  formData.append('vatNumber', vatNumber)
  formData.append('companyEmail', companyEmail)
  formData.append('companyPhone', companyPhone)
  formData.append('companyAddress', companyAddress)
  formData.append('companyType', companyType)
  formData.append('activityType', activityType)
  formData.append('foundingDocumentName', foundingDocumentName)
  formData.append('capitalSourceName', capitalSourceName)

  formData.append('foundingDocument', foundingDocument)
  formData.append('capitalSource', capitalSource)

  return axios.patch<UserModel>(`${MERCHANT_URL}/${id}`, formData, {...fileConfig, signal})
}

export function getPaginatedMerchants(
  page: number,
  take: number,
  {columnName, direction}: MerchantResultOrder,
  signal: AbortSignal,
  status: MerchantStatus
) {
  return axios.get<PaginatedMerchantsDto>(MERCHANT_PAGINATION_URL, {
    signal,
    params: {
      page,
      take,
      columnName,
      direction,
      ...status,
    },
  })
}

export function getFiles(merchantId: number, signal: AbortSignal) {
  return axios.get<PartialMerchantFiles>(`${MERCHANT_URL}/${merchantId}/files`, {signal})
}

export function updateMerchantStatus(id: number, signal: AbortSignal, status: MerchantStatus) {
  return axios.patch(`${MERCHANT_STATUS_URL}/${id}`, status, {signal})
}
