import {Modal} from 'react-bootstrap'

type Props = {
  show: boolean
  closeModal: () => void
  callback: () => void
  targetName: string
  action: string
  intl: (id: string) => string
}

export default function MerchantActionModal(props: Props) {
  const {show, closeModal, callback, action, targetName, intl} = props

  const handleOnClick = () => {
    callback()
    closeModal()
  }

  const handleClose = () => {
    closeModal()
  }

  return (
    <Modal
      id='merchant-action-modal'
      show={show}
      onHide={handleClose}
      centered
      size='sm'
      dialogClassName='justify-content-center'
      contentClassName='w-250px'
    >
      <Modal.Header className='border-0 justify-content-center p-5 pb-0' closeButton>
        <Modal.Title className='ms-15'>{intl('ADMIN.MODAL.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-5 mt-5'>
        <div className='flex-column ms-auto me-auto user-select-none mw-200px mw-sm-100 text-center'>
          {intl('ADMIN.MODAL.DESCRIPTION.1')}
          <span className='text-danger'>{action}</span>
          {intl('ADMIN.MODAL.DESCRIPTION.2')}
          {targetName}?
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0 justify-content-center p-5'>
        <div className='d-flex justify-content-between w-100'>
          <button
            id='action-modal-cancel'
            className='btn-light btn btn-sm bg-hover-light text-success w-50 me-1'
            onClick={handleClose}
          >
            {intl('ADMIN.MODAL.CANCEL')}
          </button>
          <button
            id='action-modal-confirm'
            className='btn-light btn btn-sm bg-hover-light text-danger w-50'
            onClick={handleOnClick}
          >
            {intl('ADMIN.MODAL.CONFIRM')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
