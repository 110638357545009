import {Buffer} from 'buffer'
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import getDateString from '../orders/_dateFormat'
import {getFileSizeString, getMerchantFile, openFile, revokeUrl} from './_functions'
import {MerchantFile, MerchantFileName} from './_models'
import {allowedTypes} from './UseMerchantValidation'

type _HandleRemove = (target: MerchantFileName) => void
type _msg = (msg: string) => string

type AttachmentProps = {
  foundingDocument: File | MerchantFile | undefined
  capitalSource: File | MerchantFile | undefined
  handleRemove: _HandleRemove
}

type DynamicRowProps = {
  targetFile: MerchantFileName
  file: File | MerchantFile
  handleRemove: _HandleRemove
  msg: _msg
}

type AttachmentRowProps = {
  targetFile: MerchantFileName
  fileName: string
  fileSize: number
  uploadDate: string
  url: string
  handleRemove: _HandleRemove
  msg: _msg
}

//displays file data row
function AttachmentRow(props: AttachmentRowProps) {
  const {targetFile, fileName, fileSize, uploadDate, url, handleRemove, msg} = props

  const sizeString = getFileSizeString(fileSize)

  const date = uploadDate !== '' ? getDateString(uploadDate) : ''

  const type =
    targetFile === 'foundingDocument'
      ? msg('USER.MERCHANT.FOUNDING_DOCUMENT')
      : msg('USER.MERCHANT.CAPITAL_SOURCE')

  const handleOpen = () => {
    openFile(url, fileName)
  }

  return (
    <tr className=''>
      <td className='fw-bold'>{type}</td>
      <td className='mw-300px'>
        <button
          type='button'
          className='fw-bold text-primary bg-transparent border-0 px-0 text-start mw-300px'
          onClick={handleOpen}
        >
          {fileName}
        </button>
      </td>
      <td>{sizeString}</td>
      <td>{date}</td>
      <td>
        <button
          type='button'
          className='text-primary bg-transparent border-0 px-0'
          onClick={() => handleRemove(targetFile)}
        >
          {msg('USER.MERCHANT.ATTACHMENTS.REMOVE')}
        </button>
      </td>
    </tr>
  )
}

//handles both File and MerchantFile
function DynamicRow(props: DynamicRowProps) {
  const {targetFile, file: variableTypeFile, handleRemove, msg} = props
  const [file, setFile] = useState<MerchantFile>()
  const [url, setUrl] = useState<string>()

  const urlRef = useRef<string>()

  //converts variable type to MerchantFile, then generates URL to open file
  useEffect(() => {
    async function convertFileAndGetUrl() {
      const file = await getMerchantFile(variableTypeFile)

      const buffer = Buffer.from(file.data.data)
      const blob = new Blob([buffer], {type: allowedTypes[0]})

      const fileURL = window.URL.createObjectURL(blob)

      urlRef.current = fileURL
      setFile(file)
      setUrl(fileURL)
    }

    convertFileAndGetUrl()

    //closes URL when file changes
    return () => {
      if (urlRef.current) revokeUrl(urlRef.current)
    }
  }, [variableTypeFile])

  //show input added File
  if (file && file.size > 0 && url)
    return (
      <AttachmentRow
        targetFile={targetFile}
        fileName={file.name}
        fileSize={file.size}
        uploadDate={file.uploadDate}
        handleRemove={handleRemove}
        url={url}
        msg={msg}
      />
    )
  else return <></>
}

//displays current files inside formik
export default function MerchantAttachments(props: AttachmentProps) {
  const intl = useIntl()

  const {foundingDocument, capitalSource, handleRemove} = props

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  return (
    <div className='mb-8'>
      <label className='fw-bolder text-dark fs-4'>{msg('USER.MERCHANT.ATTACHMENTS.TITLE')}</label>
      <table className='table table-hover table-row-bordered gs-3 gy-5'>
        <thead>
          <tr className='text-gray-700'>
            <th scope='col' className=''>
              {msg('USER.MERCHANT.ATTACHMENTS.TYPE')}
            </th>
            <th scope='col' className=''>
              {msg('USER.MERCHANT.ATTACHMENTS.NAME')}
            </th>
            <th scope='col' className=''>
              {msg('USER.MERCHANT.ATTACHMENTS.SIZE')}
            </th>
            <th scope='col' className=''>
              {msg('USER.MERCHANT.ATTACHMENTS.UPLOAD_DATE')}
            </th>
            <th scope='col' className=''>
              {msg('USER.MERCHANT.ATTACHMENTS.ACTIONS')}
            </th>
          </tr>
        </thead>
        <tbody>
          {!!foundingDocument && (
            <DynamicRow
              targetFile='foundingDocument'
              file={foundingDocument}
              handleRemove={handleRemove}
              msg={msg}
            />
          )}
          {!!capitalSource && (
            <DynamicRow
              targetFile='capitalSource'
              file={capitalSource}
              handleRemove={handleRemove}
              msg={msg}
            />
          )}
        </tbody>
      </table>
    </div>
  )
}
