import {FC, lazy, Suspense} from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

//TODO: force light theme

const DemoRoutes = () => {
  const DemoPage = lazy(() => import('../pages/shop-demo/DemoPage'))
  return (
    <SuspensedView>
      <DemoPage />
    </SuspensedView>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {DemoRoutes}
