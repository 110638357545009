import {Navigate, Route, Routes} from 'react-router-dom'

import {AuthLayout} from '../../../auth/AuthLayout'
import {UnverifiedUserDashboard} from './UnverifiedUserDashboard'

const UnverifiedUserPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* Pages */}
        <Route path='dashboard' element={<UnverifiedUserDashboard />} />
        {/* Redirect everything else */}
        <Route path='*' element={<Navigate to='/unverified/dashboard' />} />
      </Route>
    </Routes>
  )
}

export {UnverifiedUserPage}
