/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Outlet} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ThemeModeProvider} from '../../../_metronic/partials'

const AuthLayout = () => {
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  const defaultLogoRedirect = '/auth'

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href={defaultLogoRedirect} className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/merchant300x77.png')}
            className='h-45px'
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <ThemeModeProvider>
            <Outlet />
          </ThemeModeProvider>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            {msg('AUTH.FOOTER.ABOUT')}
          </a>
          <a href='#' className='text-muted text-hover-primary px-2'>
            {msg('AUTH.FOOTER.CONTACT')}
          </a>
          <a href='#' className='text-muted text-hover-primary px-2'>
            {msg('AUTH.FOOTER.CONTACT_US')}
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {AuthLayout}
