import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

type props = {
  i18nId: string
}

export function AuthSuccessMessage({i18nId}: props) {
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  return (
    <div className='text-center'>
      <div className='mb-10 bg-light-success p-8 rounded '>
        <div className='text-success'>{msg(i18nId)}</div>
      </div>
      <Link className='d-grid ' to='/auth'>
        <button className='btn btn-primary'>{msg('APP.RETURN')}</button>
      </Link>
    </div>
  )
}
