import axios from 'axios'

import {PaginatedOrdersDto, ResultOrder} from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const ORDER_URL = `${API_URL}/orders/paginated`

export function getPaginatedOrders(
  shopId: number,
  merchantId: number,
  page: number,
  take: number,
  {columnName, direction}: ResultOrder,
  signal: AbortSignal
) {
  return axios.get<PaginatedOrdersDto>(ORDER_URL, {
    params: {
      shopId,
      merchantId,
      page,
      take,
      columnName,
      direction,
    },
    signal,
  })
}
