/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {setLanguage, useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {languages} from '../../../../../_metronic/partials/layout/header-menus/Languages'

const AuthLanguages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  const intl = useIntl()
  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  return (
    <div className='menu' data-kt-menu='true'>
      <div
        className='menu-item mt-3'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='right-start'
        data-kt-menu-flip='bottom'
      >
        <span className='fs-8 rounded bg-light px-3 py-3 translate-middle-y top-50 end-0'>
          <img className='w-15px h-15px rounded-1' src={currentLanguage?.flag} alt='language' />
        </span>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          {languages.map((l) => {
            return (
              <div
                className='menu-item px-3'
                key={l.lang}
                onClick={() => {
                  setLanguage(l.lang)
                }}
              >
                <a
                  href='#'
                  className={clsx('menu-link d-flex px-5', {
                    active: l.lang === currentLanguage?.lang,
                  })}
                >
                  <span className='symbol symbol-20px me-4'>
                    <img className='rounded-1' src={l.flag} alt='flag' />
                  </span>
                  {msg(`LANGUAGE.${l.name}`)}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export {AuthLanguages}
