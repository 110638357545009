/* eslint-disable jsx-a11y/anchor-is-valid */
import {Navigate, Route, Routes} from 'react-router-dom'

import {AuthLayout} from './AuthLayout'
import {EmailVerification} from './components/EmailVerification'
import {Login} from './components/Login'
import {PasswordResetGenerator} from './components/password-reset/PasswordResetGenerator'
import {PasswordResetter} from './components/password-reset/PasswordResetter'
import {Registration} from './components/Registration'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='password-reset/*'>
        <Route path=':id/:token' element={<PasswordResetter />} />
        <Route path='*' element={<PasswordResetGenerator />} />
      </Route>
      <Route path='*' element={<Navigate to='/auth/login' />} />
    </Route>
  </Routes>
)

const EmailVerificationPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='*' element={<EmailVerification />} />
    </Route>
  </Routes>
)

export {AuthPage, EmailVerificationPage}
