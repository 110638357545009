import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'

import {PasswordMeterComponent} from '../../../../../_metronic/assets/ts/components'
import {useErrorManagerForComponent} from '../../../errors/ErrorManager'
import {passwordRegex} from '../../AuthRegex'
import {resetPassword} from '../../core/_requests'
import {AuthHeader} from '../auth-header/AuthHeader'
import {AuthSuccessMessage} from '../AuthSuccessMessage'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const changePasswordSchema = Yup.object().shape({
  id: Yup.number().integer().moreThan(0).required(),
  token: Yup.string().length(96).required(),
})

export function PasswordResetter() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [badParams, setBadParams] = useState(false)
  const getErrorMessage = useErrorManagerForComponent('PasswordResetter')
  const intl = useIntl()
  const params = useParams()
  const {id: userId = '', token = ''} = params

  const id = parseInt(userId)

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const passwordResetterSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, msg('YUP.MINIMUM_8'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.PASSWORD_REQUIRED'))
      .matches(passwordRegex, msg('YUP.PASSWORD_REGEX')),
    confirmPassword: Yup.string()
      .min(3, msg('YUP.MINIMUM_3'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.CONFIRMATION_REQUIRED'))
      .oneOf([Yup.ref('password')], msg('YUP.PASSWORDS_MATCH')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: passwordResetterSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(id, token, values.password)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setStatus('')
          })
          .catch((err) => {
            const errMsg = getErrorMessage(err?.response?.status)
            setStatus(errMsg)
            setHasErrors(true)
          })
          .finally(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  useEffect(() => {
    changePasswordSchema
      .validate({id, token})
      .then(() => {
        setBadParams(false)
      })
      .catch(() => {
        setBadParams(true)
      })
  }, [id, token])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  if (badParams)
    return (
      <h3 className='d-flex flex-center text-danger'>
        {msg('AUTH.RESET.RESETTER.ERROR.INCORRECT_PARAMETERS')}
      </h3>
    )

  if (hasErrors === false) return <AuthSuccessMessage i18nId='AUTH.RESET.RESETTER.SUCCESS' />

  return (
    <>
      <AuthHeader />

      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>{msg('AUTH.RESET.RESETTER.TITLE')}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {msg('AUTH.RESET.RESETTER.DIRECTIONS')}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger text-center'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>{msg('APP.PASSWORD')}</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={msg('APP.PASSWORD')}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>{msg('APP.PASSWORD_RULES')}</div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {msg('APP.CONFIRM_PASSWORD')}
          </label>
          <input
            type='password'
            placeholder={msg('APP.CONFIRM_PASSWORD')}
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary me-4'
            data-kt-indicator={loading ? 'on' : ''}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <span className='indicator-label'>{msg('APP.SUBMIT')}</span>
            <span className='indicator-progress'>
              {msg('APP.LOADING')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {msg('APP.CANCEL')}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
