/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx'
import {useFormik} from 'formik'
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'

import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useErrorManagerForComponent} from '../../errors/ErrorManager'
import {emailRegex, passwordRegex} from '../AuthRegex'
import {getUserByToken, register} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {AuthHeader} from './auth-header/AuthHeader'

const initialValues = {
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

export function Registration() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const getErrorMessage = useErrorManagerForComponent('Registration')

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, msg('YUP.EMAIL_REGEX'))
      .min(3, msg('YUP.MINIMUM_3'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.EMAIL_REQUIRED')),
    password: Yup.string()
      .min(8, msg('YUP.MINIMUM_8'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.PASSWORD_REQUIRED'))
      .matches(passwordRegex, msg('YUP.PASSWORD_REGEX')),
    changepassword: Yup.string()
      .min(3, msg('YUP.MINIMUM_3'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.CONFIRMATION_REQUIRED'))
      .oneOf([Yup.ref('password')], msg('YUP.PASSWORDS_MATCH')),
    acceptTerms: Yup.bool().oneOf([true], msg('YUP.ACCEPT_TERMS')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(values.email, values.password)
        setStatus('')
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (err: any) {
        const errMsg = getErrorMessage(err?.response?.status)
        setStatus(errMsg)

        saveAuth(undefined)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <AuthHeader />

      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>{msg('APP.SIGN_UP')}</h1>
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>{msg('APP.WAYS')}</span>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger text-center'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>{msg('APP.EMAIL')}</label>
          <input
            placeholder={msg('APP.EMAIL')}
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>{msg('APP.PASSWORD')}</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={msg('APP.PASSWORD')}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>{msg('APP.PASSWORD_RULES')}</div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {msg('APP.CONFIRM_PASSWORD')}
          </label>
          <input
            type='password'
            placeholder={msg('APP.CONFIRM_PASSWORD')}
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <span>
              {msg('AUTH.REGISTER.TERMS_1')}{' '}
              <a
                href='https://keenthemes.com/metronic/?page=faq'
                target='_blank'
                className='ms-1 link-primary fs-5'
              >
                {msg('AUTH.REGISTER.TERMS_2')}
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            data-kt-indicator={loading ? 'on' : ''}
          >
            <span className='indicator-label'>{msg('APP.SUBMIT')}</span>
            <span className='indicator-progress'>
              {msg('APP.LOADING')}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              {msg('APP.CANCEL')}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
