import {useAuth} from '../../../auth'
import CreateMerchantForm from '../merchant/CreateMerchantForm'
import UpdateMerchantForm from '../merchant/UpdateMerchantForm'
import SettingsNavigation from './SettingsNavigation'

const createMerchant = (
  <>
    <SettingsNavigation />
    <CreateMerchantForm />
  </>
)

const updateMerchant = (
  <>
    <SettingsNavigation />
    <UpdateMerchantForm />
  </>
)

export default function CompanySettingsDashboard() {
  const {currentUser} = useAuth()
  const create = (currentUser?.merchants?.length || 0) < 1

  if (currentUser?.role === 'admin') return <h1>Admin</h1>
  if (create) return createMerchant
  else return updateMerchant
}
