import {isValidPhoneNumber} from 'libphonenumber-js'
import {useIntl} from 'react-intl'
import isVAT from 'validator/lib/isVAT'
import * as Yup from 'yup'

import {getFileName, isSupportedCountry} from './_functions'
import {MerchantFileName} from './_models'

export const allowedTypes = ['application/pdf']
const maxFileSize = 1 * 1024 * 1024

export default function useMerchantValidation() {
  const intl = useIntl()

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const getErrorCode = (statusCode: any, message: string | string[]) => {
    if (typeof statusCode !== 'number') return 0
    if (statusCode === 401 || statusCode === 404) return statusCode

    const taxNumberError = message.includes('identification number')
    const emailError = message.includes('email')
    const unchangedError = message.includes('data same as current')

    if (taxNumberError && emailError) return 1
    if (taxNumberError) return 2
    if (emailError) return 3
    if (unchangedError) return 4

    return statusCode
  }

  //method used in IsPhoneNumber.js from class-validator, changed to use isValidPhoneNumber,
  //because default method allows through non number characters, via checking parsed string
  function isPhoneNumber(value: string) {
    try {
      const result = value === null || value === void 0 ? void 0 : isValidPhoneNumber(value)
      return !!result
    } catch (error) {
      console.log(error)
      return false
    }
  }

  //uses regex (with few also counting checksum) to validate vat numbers
  function isTaxNumber(value: string) {
    try {
      if (!value) return false

      //first 2 digits - country code
      const country = value.slice(0, 2)

      //check against our supported countries
      if (!isSupportedCountry(country)) return false

      const result = isVAT(value, country)
      return !!result
    } catch (error) {
      console.log(error)
      return false
    }
  }

  function validateTaxNumber() {
    const name1 = 'validateCountry'
    const err1 = msg('YUP.VATNUMBER_COUNTRY')
    const checkCountry = (value: string | undefined) => {
      if (!value) return false
      //first 2 digits - country code
      const country = value.slice(0, 2)

      return isSupportedCountry(country)
    }

    const name2 = 'validateTaxNumber'
    const err2 = msg('YUP.VATNUMBER_NUMBER')
    const checkTaxNumber = (value: string | undefined) => {
      if (!value) return false

      return isTaxNumber(value)
    }

    return Yup.string()
      .test(name1, err1, checkCountry)
      .test(name2, err2, checkTaxNumber)
      .required(msg('YUP.VATNUMBER_REQUIRED'))
  }

  function validatePhoneNumber() {
    const name = 'validPhoneNumber'
    const err = msg('YUP.PHONE_VALIDATION')
    const validate = (value: string | undefined) => {
      if (!value) return false

      return isPhoneNumber(value)
    }

    return Yup.string().test(name, err, validate).required(msg('YUP.PHONE_REQUIRED'))
  }

  function validateFile(target: MerchantFileName) {
    const name1 = 'fileType'
    const err1 = msg('USER.MERCHANT.ERROR.FILE_TYPE')
    const checkType = (value: File | undefined) => {
      if (!value) return false

      return allowedTypes.includes(value.type)
    }

    const name2 = 'fileSize'
    const err2 = msg('USER.MERCHANT.ERROR.FILE_SIZE')
    const checkSize = (value: File | undefined) => {
      if (!value) return false

      return value.size <= maxFileSize
    }

    const name3 = 'fileName'
    const err3 = msg('USER.MERCHANT.ERROR.FILE_NAME.SHORT')
    const err4 = msg('USER.MERCHANT.ERROR.FILE_NAME.LONG')
    const checkNameShort = (value: File | undefined) => {
      if (!value) return false

      const fileName = getFileName(value)
      return fileName.length > 0
    }
    const checkNameLong = (value: File | undefined) => {
      if (!value) return false

      const fileName = getFileName(value)
      return fileName.length <= 255
    }

    const reqMsg =
      target === 'foundingDocument'
        ? msg('YUP.FOUNDING_DOCUMENT_REQUIRED')
        : msg('YUP.CAPITAL_SOURCE_REQUIRED')

    return Yup.mixed<File>()
      .required(reqMsg)
      .test(name1, err1, checkType)
      .test(name2, err2, checkSize)
      .test(name3, err3, checkNameShort)
      .test(name3, err4, checkNameLong)
  }

  return {validateFile, validatePhoneNumber, validateTaxNumber, getErrorCode}
}
