import clsx from 'clsx'
import {useFormik} from 'formik'
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'

import {useErrorManagerForComponent} from '../../errors/ErrorManager'
import {emailRegex} from '../AuthRegex'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {AuthHeader} from './auth-header/AuthHeader'

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const getErrorMessage = useErrorManagerForComponent('Login')

  const msg = (id: string) => {
    return intl.formatMessage({id})
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, msg('YUP.EMAIL_REGEX'))
      .min(3, msg('YUP.MINIMUM_3'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.EMAIL_REQUIRED')),
    password: Yup.string()
      .min(8, msg('YUP.MINIMUM_8'))
      .max(50, msg('YUP.MAXIMUM_50'))
      .required(msg('YUP.PASSWORD_REQUIRED')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (err: any) {
        const errMsg = getErrorMessage(err?.response?.status)
        setStatus(errMsg)

        saveAuth(undefined)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <AuthHeader />

      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>{msg('APP.SIGN_IN')}</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Separator */}
        <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>{msg('APP.WAYS')}</span>
        </div>
        {/* end::Separator */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger text-center'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>{msg('APP.EMAIL')}</label>
          <input
            placeholder={msg('APP.EMAIL')}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>{msg('APP.PASSWORD')}</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/password-reset' className='link-primary'>
            {msg('APP.FORGOT_PASSWORD')}
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            data-kt-indicator={loading ? 'on' : ''}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <span className='indicator-label'>{msg('APP.CONTINUE')}</span>
            <span className='indicator-progress'>
              {msg('APP.LOADING')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          {msg('AUTH.LOGIN.NOT_REGISTERED')}{' '}
          <Link to='/auth/registration' className='link-primary'>
            {msg('APP.SIGN_UP')}
          </Link>
        </div>
      </form>
    </>
  )
}
