import {useAuth} from '../../../../auth'
import MerchantTable from './MerchantTable'

export default function MerchantsPage() {
  const {currentUser} = useAuth()
  const {role = 'user'} = currentUser ?? {}

  if (role !== 'admin') return <>Unauthorized</>
  return <>{<MerchantTable />}</>
}
